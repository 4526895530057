"use client"

import { Skeleton } from "@/components/ui/feedback/Skeleton"
import { Box } from "@/components/ui/layout/Box"
import useMenu from "@/components/ui/navigation/Menu/useMenu"
import { useTranslation } from "@/i18n"

import PersonAvatar, {
  type PersonAvatarProps,
} from "@/app/[locale]/(person)/_components/PersonAvatar"
import useAside from "@/app/_components/Aside/hooks/useAside"
import appDe from "@/app/_messages/de.json"
import { trpc } from "@/app/_trpc/client"
import { Badge } from "@/components/ui/data-display/Badge"
import { Divider } from "@/components/ui/data-display/Divider"
import { ListItemIcon } from "@/components/ui/data-display/ListItemIcon"
import { ListItemText } from "@/components/ui/data-display/ListItemText"
import { Tooltip } from "@/components/ui/data-display/Tooltip"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@/components/ui/feedback/Dialog"
import useDialog from "@/components/ui/feedback/Dialog/useDialog"
import { Button } from "@/components/ui/inputs/Button"
import { IconButton } from "@/components/ui/inputs/IconButton"
import { Menu } from "@/components/ui/navigation/Menu"
import { MenuItem } from "@/components/ui/navigation/MenuItem"
import useGetMailToSupport from "@/hooks/useGetMailToSupport"
import { Intervals } from "@/utils/constants"
import { NEXT_PUBLIC_HELP_CENTER_URL } from "@/utils/env"
import { formatPersonName, getDayjsDateTimeFormat } from "@/utils/format"
import { useGetUserSessionData } from "@/utils/lib"
import {
  COMMON_USER_ROUTE,
  LAWFIRM_ONLY_ROUTE,
  PUBLIC_ROUTE,
  SERVICE_PROVIDER_ONLY_ROUTE,
} from "@/utils/middleware"
import { replaceSlugs } from "@/utils/navigation"
import {
  mdiAccount,
  mdiCreation,
  mdiDomain,
  mdiHelpCircle,
  mdiLifebuoy,
  mdiLogout,
  mdiRefresh,
} from "@mdi/js"
import Icon from "@mdi/react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import dayjs from "dayjs"
import { signOut } from "next-auth/react"
import { useParams, useRouter } from "next/navigation"
import { useSnackbar } from "notistack"
import { useCallback, useEffect, useMemo, useState } from "react"
import de from "../../_messages/de.json"
import { isAdmin, isUserDataOfLawfirm } from "../../_utils"

export default function UserAvatar(personAvatarProps: PersonAvatarProps) {
  const queryClient = useQueryClient()
  const { t } = useTranslation(de)
  const { t: tApp, tHtml: tHtmlApp } = useTranslation(appDe)
  const router = useRouter()
  const { locale } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const { menuProps, closeMenu, openMenu } = useMenu()
  const {
    openDialog: openNewVersionDialog,
    closeDialog: closeNewVersionDialog,
    dialogProps: newVersionDialogProps,
  } = useDialog()
  const { asideSearch, closeAside } = useAside()

  const userSessionDataQuery = useGetUserSessionData()
  const userData = useMemo(
    () => userSessionDataQuery.data,
    [userSessionDataQuery.data],
  )
  const isOwnUserAdmin = isAdmin(userData)

  const [suppressNewVersionDialog, setSuppressNewVersionDialog] =
    useState(false)
  const [currentVersion, setCurrentVersion] = useState<{
    version: string | null
    release_date: string | null
  }>()

  const systemVersionQuery = trpc.system.version.useQuery(undefined, {
    refetchInterval: Intervals.SystemInformationRefetch,
  })

  const newVersionAvailable = useMemo(
    () =>
      Boolean(
        currentVersion?.version &&
          currentVersion?.version !== systemVersionQuery.data?.version,
      ),
    [currentVersion, systemVersionQuery.data],
  )

  useEffect(() => {
    if (systemVersionQuery.data?.version && !currentVersion?.version) {
      // cache new version if fetched for the first time
      setCurrentVersion(systemVersionQuery.data)
    }
  }, [currentVersion, systemVersionQuery.data])

  useEffect(() => {
    if (
      newVersionAvailable &&
      !newVersionDialogProps.open &&
      !suppressNewVersionDialog
    ) {
      openNewVersionDialog()
    }
  }, [
    suppressNewVersionDialog,
    openNewVersionDialog,
    newVersionDialogProps.open,
    newVersionAvailable,
  ])

  const ownUserQuery = trpc.entity.user.getOwn.useQuery(undefined, {
    enabled: !!userData?.userId,
    retry: false,
  })

  useEffect(() => {
    if (ownUserQuery.error?.data?.code === "UNAUTHORIZED") {
      handleLogout()
    }
  }, [ownUserQuery.error])

  const { mailTo, isFetching: isFetchingMailTo } = useGetMailToSupport(
    formatPersonName(ownUserQuery.data?.data, {
      withSalutation: false,
      withTitle: false,
    }),
  )

  const logoutMutation = useMutation({
    mutationFn: () =>
      signOut({
        callbackUrl: replaceSlugs(COMMON_USER_ROUTE.IN, { locale }),
      }),
    onError: () => {
      enqueueSnackbar(t("user.logout.error"), {
        variant: "error",
      })
    },
    onSuccess: () => {
      closeAside()
      queryClient.clear()
      queryClient.resetQueries()
      router.push(replaceSlugs(PUBLIC_ROUTE.SIGNIN, { locale }, asideSearch))
    },
  })

  const handleCloseNewVersionDialog = useCallback(() => {
    setSuppressNewVersionDialog(true)
    closeNewVersionDialog()
  }, [closeNewVersionDialog])

  const handleLogout = useCallback(async () => {
    logoutMutation.mutate()
    closeMenu()
  }, [closeMenu, logoutMutation])

  const handleEditUser = useCallback(() => {
    const userId = userData?.userId ?? ""

    closeMenu()
    if (isUserDataOfLawfirm(userData)) {
      router.push(
        replaceSlugs(
          `${LAWFIRM_ONLY_ROUTE.IN}/[lawyerId]/own-user`,
          {
            locale,
            lawyerId: userData?.organizationId ?? "",
            userId,
          },
          asideSearch,
        ),
      )
    } else {
      router.push(
        replaceSlugs(
          `${SERVICE_PROVIDER_ONLY_ROUTE.IN}/[cardealerId]/own-user`,
          {
            locale,
            cardealerId: userData?.organizationId ?? "",
            userId,
          },
          asideSearch,
        ),
      )
    }
  }, [closeMenu, userData, locale, router, asideSearch])

  const handleEditAccount = useCallback(() => {
    closeMenu()
    if (isUserDataOfLawfirm(userData)) {
      router.push(
        replaceSlugs(
          `${LAWFIRM_ONLY_ROUTE.IN}/[lawyerId]/settings/organization`,
          {
            locale,
            lawyerId: userData?.organizationId ?? "",
          },
          asideSearch,
        ),
      )
    } else {
      router.push(
        replaceSlugs(
          `${SERVICE_PROVIDER_ONLY_ROUTE.IN}/[cardealerId]/settings/organization`,
          {
            locale,
            cardealerId: userData?.organizationId ?? "",
          },
          asideSearch,
        ),
      )
    }
  }, [closeMenu, userData, locale, router, asideSearch])

  const handleReload = useCallback(() => {
    window.location.reload()
  }, [])

  if (
    ownUserQuery.isFetching ||
    systemVersionQuery.isFetching ||
    logoutMutation?.isPending
  ) {
    return (
      <Box p={0.625}>
        <Skeleton variant="circular" width={32} height={32} />
      </Box>
    )
  }

  return (
    <>
      <IconButton size="small" onClick={openMenu}>
        <Badge
          variant="dot"
          badgeContent={newVersionAvailable ? "" : 0}
          color="info"
          overlap="circular"
        >
          <PersonAvatar
            person={ownUserQuery.data?.data}
            size="medium"
            {...personAvatarProps}
          />
        </Badge>
      </IconButton>

      <Menu
        id="user-menu"
        {...menuProps}
        MenuListProps={{
          dense: true,
        }}
      >
        <MenuItem data-testid="user-menu-option-edit" onClick={handleEditUser}>
          <ListItemIcon>
            <Icon path={mdiAccount} size={0.75} />
          </ListItemIcon>
          <ListItemText>{t("user.edit.text")}</ListItemText>
        </MenuItem>
        <MenuItem
          data-testid="user-menu-option-edit-account"
          onClick={handleEditAccount}
        >
          <ListItemIcon>
            <Icon path={mdiDomain} size={0.75} />
          </ListItemIcon>
          <ListItemText>{t("user.editAccount.text")}</ListItemText>
        </MenuItem>
        <a href={mailTo} target="_blank" rel="noreferrer">
          <MenuItem
            data-testid="user-menu-option-support"
            disabled={isFetchingMailTo}
          >
            <ListItemIcon>
              <Icon path={mdiHelpCircle} size={0.75} />
            </ListItemIcon>
            <ListItemText>{t("user.support.text")}</ListItemText>
          </MenuItem>
        </a>
        <a href={NEXT_PUBLIC_HELP_CENTER_URL} target="_blank" rel="noreferrer">
          <MenuItem
            data-testid="user-menu-option-support"
            disabled={isFetchingMailTo}
          >
            <ListItemIcon>
              <Icon path={mdiLifebuoy} size={0.75} />
            </ListItemIcon>
            <ListItemText>{t("user.supportCenter.text")}</ListItemText>
          </MenuItem>
        </a>
        <MenuItem data-testid="user-menu-option-logout" onClick={handleLogout}>
          <ListItemIcon>
            <Icon path={mdiLogout} size={0.75} />
          </ListItemIcon>
          <ListItemText>{t("user.logout.text")}</ListItemText>
        </MenuItem>
        <Divider />
        <Tooltip
          title={tApp("systemVersion.releaseDate", {
            date: currentVersion?.release_date
              ? dayjs(currentVersion?.release_date).format(
                  getDayjsDateTimeFormat(),
                )
              : tApp("systemVersion.unknown"),
          })}
          placement="bottom"
        >
          <Box>
            <MenuItem disabled>
              <ListItemIcon>
                <Icon path={mdiCreation} size={0.75} />
              </ListItemIcon>
              <ListItemText
                primary={tApp("systemVersion.label")}
                secondary={`${
                  currentVersion?.version ?? tApp("systemVersion.unknown")
                }`}
              />
            </MenuItem>
          </Box>
        </Tooltip>
        {newVersionAvailable && (
          <MenuItem onClick={handleReload}>
            <ListItemIcon>
              <Badge
                variant="dot"
                content=""
                color="info"
                overlap="rectangular"
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <Icon path={mdiRefresh} size={0.75} />
              </Badge>
            </ListItemIcon>
            <ListItemText
              primary={tApp("systemVersion.newVersion.loadNewVersion")}
              secondary={tHtmlApp("systemVersion.newVersion.versionInfo", {
                version: `${
                  systemVersionQuery.data?.version ??
                  tApp("systemVersion.unknown")
                }`,
                date: systemVersionQuery.data?.release_date
                  ? dayjs(systemVersionQuery.data?.release_date).format(
                      getDayjsDateTimeFormat(),
                    )
                  : tApp("systemVersion.unknown"),
              })}
            />
          </MenuItem>
        )}
      </Menu>

      <Dialog
        {...newVersionDialogProps}
        aria-labelledby="new-version-dialog-title"
        aria-describedby="new-version-dialog-description"
        maxWidth="xs"
        onClose={handleCloseNewVersionDialog}
      >
        <DialogTitle id="new-version-dialog-title">
          {tApp("systemVersion.newVersion.title")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="new-version-dialog-description">
            {tHtmlApp("systemVersion.newVersion.message", {
              version: `${
                systemVersionQuery.data?.version ??
                tApp("systemVersion.unknown")
              }`,
              date: systemVersionQuery.data?.release_date
                ? dayjs(systemVersionQuery.data?.release_date).format(
                    getDayjsDateTimeFormat(),
                  )
                : tApp("systemVersion.unknown"),
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={handleCloseNewVersionDialog}>
            {tApp("systemVersion.newVersion.close")}
          </Button>
          <Button color="info" size="small" autoFocus onClick={handleReload}>
            {tApp("systemVersion.newVersion.loadVersion")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
